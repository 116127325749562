<template>
  <div class="bottom-box">
    <div>
      <div v-for="(item, index) in routerList" :key="index" @click="router.push(item.url)">
        <span>{{ item.title }}</span>
        <img src="@/assets/images/home/prev-icon.png" />
      </div>
    </div>
    <div class="bottom-box-text">
      <div>
        <div>上海市徐汇区钦州北路1001号光启园7号楼6层</div>
        <div>沪ICP备2023012463号—1</div>
        <div>版权所有 ©️ 2023上海凝聚元界信息科技有限公司</div>
        <div><img src="@/assets/images/beian-icon.png" /><span>沪公网安备 31010402010108号</span></div>
      </div>
      <div>
        <div>
          <img src="@/assets/images/code1.png" />
          <div>公众号</div>
        </div>
        <div>
          <img src="@/assets/images/code2.png" />
          <div>视频号</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useRouter } from 'vue-router';

const router = useRouter()

const routerList = [
  {
    title: '产品服务',
    url: '/studio'
  },
  {
    title: '解决方案',
    url: '/solution'
  },
  {
    title: '关于我们',
    url: '/about-us'
  },
  {
    title: '合作咨询',
    url: '/counselling'
  }
]
</script>
<style scoped lang="less">
.bottom-box {
  color: #fff;
  font-size: 28rem;
  padding: 28rem 36rem 0;
  box-sizing: border-box;

  &>div:first-child>div {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70rem;
    border-bottom: 2rem solid #201F1F;

    img {
      width: 32rem;
      height: 16rem;
    }
  }

  .bottom-box-text {
    margin-top: 24rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-weight: 500;
    font-size: 16rem;
    color: #929292;

    &>div:first-child>div {
      margin-bottom: 8rem;

      img {
        width: 20rem;
        height: auto;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
        margin-left: 4rem;
      }
    }

    &>div:last-child {
      margin-right: -6rem;
      width: 236rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // font-size: 0;

      &>div {
        width: 108rem;
        height: 123rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      img {
        width: 108rem;
        height: 108rem;
      }
    }
  }
}
</style>