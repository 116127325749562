export const typeList = [
  {
    header: "文娱解决方案",
    content:
      "将元宇宙与内容资源融合创新，应用虚拟现实和新媒体技术打造沉浸式的时空新体验，实现“文化+科技”“文娱+消费”的新业态模式，推动文娱产业升级。",
  },
  {
    header: "工业解决方案",
    content:
      "使用XR技术模拟复杂机械设备的装配、调试、维修维护等专业操作培训，提高员工培训效率，降低操作失误率，减少安全风险，为企业降本增效。",
  },
  {
    header: "教育解决方案",
    content:
      "针对教育行业课程创建教学场景和虚拟仿真课件，实现教学内容的场景化和教学质量的闭环管理，突出互动性和趣味性，达到高效、有趣、安全的教学效果。",
  },
  {
    header: "空间视觉技术",
    content:
      "混空自研的空间视觉技术是帮助用户通过视觉信息对设备或物体进行高精度、实时性的定位与姿态的估计。空间视觉技术能显著提升用户体验，如增强现实（AR）中的虚实融合效果，通过不断优化算法和引入新技术，VPS技术致力于成为未来智能系统中不可或缺的一部分。",
  },
  {
    header: "AR小程序",
    content:
      "AR小程序是结合了小程序和AR（增强现实）技术的创新应用，通过在手机屏幕上叠加虚拟图像或信息到真实世界中，使用户能够与虚拟物体进行互动。",
  },

  {
    header: "VR虚拟仿真",
    content:
      "虚拟仿真，利用计算机技术模拟现实世界或想象中的环境和过程，让用户能够在一个虚拟的空间中进行交互和体验。这种技术广泛应用于教育、医疗、军事、工业设计等领域，它能够提供一个安全、可控的环境，让人们在不实际接触真实设备或环境的情况下进行训练和学习。",
  },
  {
    header: "虚拟博物馆",
    content:
      "虚拟博物馆是通过数字技术将实体博物馆的藏品、展览以及相关信息进行数字化处理，并在网络上展示的平台。它打破了实体博物馆在时间、空间上的限制，使观众可以随时随地通过互联网访问和体验博物馆的内容。",
  },
  {
    header: "AR导航导览",
    content:
      "AR导航导览基于增强现实技术，通过特定的硬件设备（如手机、AR眼镜等）和软件平台，将虚拟的导航信息、景点介绍、互动元素等叠加到现实世界的场景中。用户只需通过设备屏幕或眼镜，就能直观地看到这些信息，从而实现更加便捷、有趣的导航和导览。",
  },
  {
    header: "元宇宙展厅",
    content:
      "元宇宙展厅是一种基于虚拟现实（VR）、增强现实（AR）、区块链等前沿技术构建的虚拟空间。它能够模拟现实世界中的展览环境，甚至超越现实的限制，创造出无限可能的展示场景。",
  },
];

export const modelTypes = {
  vision_ar: [
    // 空间视觉技术
    {
      header: "全面的数据获取",
      image: require("@/assets/images/ar-images/first-pic.png"),
      remarkList: [
        "全面收集三维空间信息，对物体形状、大小和位置全面感知；",
        "融合点云、图片等多模态信息，提供更丰富、全面的环境感知能力。",
      ],
    },
    {
      header: "实时性与鲁棒性",
      image: require("@/assets/images/ar-images/two-pic.png"),
      remarkList: [
        "快速响应环境变化，实时更新位置信息；",
        "确保使用过程中的连续性和稳定性；",
        "能适应不同光照条件、天气状况以及动态障碍物等复杂环境。",
      ],
    },
    {
      header: "高精度定位",
      image: require("@/assets/images/ar-images/three-pic.png"),
      remarkList: [
        "捕捉和分析环境图像中的特征点；",
        "实现高精度的位置识别和方向指引。",
      ],
    },
    {
      header: "个性化定制",
      image: require("@/assets/images/ar-images/four-pic.png"),
      remarkList: [
        "根据用户的个性化需求进行定制和优化；",
        "提供更贴心、个性化的导航服务。",
      ],
    },
  ],
  "wechart-ar": [
    // AR小程序
    {
      header: "服装销售",
      image: require("@/assets/images/ar-images/clothing-sales.png"),
      remarkList: [
        "用户可以通过微信小程序扫描现实场景，试穿虚拟服装、试戴鞋帽，直观了解服装的效果和搭配方式。",
      ],
    },
    {
      header: "餐饮营销",
      image: require("@/assets/images/ar-images/catering-marketing.png"),
      remarkList: [
        "通过AR互动小程序点餐，食客可以查看关于餐品的动效介绍和制作过程，在获得点餐指引的同时也刺激食客消费。",
      ],
    },
    {
      header: "快消品营销",
      image: require("@/assets/images/ar-images/FMCG.png"),
      remarkList: [
        "扫码快消品线下门店海报、或产品，进入AR互动场景，了解品牌故事、领取优惠券、获得门店活动指引等。",
      ],
    },
    {
      header: "房地产销售",
      image: require("@/assets/images/ar-images/real-estate.png"),
      remarkList: [
        "用户可以通过微信小程序扫描房间，获得房间的立体户型图、装修布局等虚拟信息。",
      ],
    },
    {
      header: "室内装修",
      image: require("@/assets/images/ar-images/interior-decoration.png"),
      remarkList: [
        "微信小程序扫描室内空间，自由摆放不同的家具，灵活切换装修风格，帮助决策最佳装修方案。",
      ],
    },
    {
      header: "IP互动",
      image: require("@/assets/images/ar-images/IP-interaction.png"),
      remarkList: [
        "手机小程序扫描任一平面，出现品牌授权的虚拟IP玩偶，用户可以随时随地跟品牌互动、增强粘性。",
      ],
    },
  ],
  "navigation-guide": [
    {
      header: "旅游景区AR导览",
      image: require("@/assets/images/ar-images/lyjq-dh.png"),
      remarkList: [
        "在景区内部署AR导航导览，为游客提供便捷的导航服务和丰富的景点介绍，提升游客的游览体验和满意度。",
      ],
    },
    {
      header: "博物馆和展厅AR导航",
      image: require("@/assets/images/ar-images/bwgdh.png"),
      remarkList: [
        "利用AR技术展示逛展线路，并在参观动线中植入展品背后的故事和历史背景讲解等丰富信息。",
      ],
    },
    {
      header: "商业空间AR导航",
      image: require("@/assets/images/ar-images/sykjdh.png"),
      remarkList: [
        "在商场、机场、高铁站等商业空间中提供AR导航服务，可以帮助消费者快速找到目标店铺或设施，提升购物体验。",
      ],
    },
    {
      header: "智慧园区AR导航",
      image: require("@/assets/images/ar-images/zhyq-dh.png"),
      remarkList: [
        "在景区内部署AR导航导览系统，可以为游客提供便捷的导航服务和丰富的景点介绍，提升游客的游览体验和满意度。",
      ],
    },
    {
      header: "停车场AR导航",
      image: require("@/assets/images/ar-images/tccdh.png"),
      remarkList: [
        "通过给停车场部署AR导航导览，帮助车主在室内快速找车，适用于商超、医院、景区、高铁站、机场等大型停车场。",
      ],
    },
    {
      header: "大型活动AR导航",
      image: require("@/assets/images/ar-images/dxhddh.png"),
      remarkList: [
        "利用AR技术指引现场参观线路，为活动配套服务的入口提供必要指引。",
      ],
    },
  ],
  metaverse: [
    {
      header: "文化艺术展览",
      image: require("@/assets/images/ar-images/whysz.png"),
      remarkList: [
        "元宇宙展厅可以为艺术家和策展人提供一个全新的展示平台，让观众在虚拟空间中欣赏艺术作品，实现更加深入的艺术交流。",
      ],
    },
    {
      header: "商业展览",
      image: require("@/assets/images/ar-images/syzl.png"),
      remarkList: [
        "商业展览中利用元宇宙展厅展示产品、服务和品牌形象，吸引更多潜在客户的关注，提升品牌影响力。",
      ],
    },
    {
      header: "教育培训",
      image: require("@/assets/images/ar-images/jypx.png"),
      remarkList: [
        "元宇宙展厅可以模拟各种教育场景，为学生提供更加生动、直观的学习体验，提高学习效果。",
      ],
    },
    {
      header: "旅游观光",
      image: require("@/assets/images/ar-images/lygg.png"),
      remarkList: [
        "通过元宇宙展厅，观众可以足不出户地游览世界各地的名胜古迹，感受不同文化的魅力。",
      ],
    },
    {
      header: "企业展厅",
      image: require("@/assets/images/ar-images/qyzt.png"),
      remarkList: [
        "企业部署元宇宙展厅，可以在物理展览的基础上叠加动态展示，更好地展示企业的产品、发展脉络、品牌文化和业务场景等。",
      ],
    },
    {
      header: "城市展厅",
      image: require("@/assets/images/ar-images/cszt.png"),
      remarkList: [
        "城市元宇宙展厅可以以城市的发展脉络作为轴线，展示城市发展的历史沿革和精彩故事、文化沉淀、里程碑人物和事件等。",
      ],
    },
  ],
  "wechart-education": [
    // AR小程序-教育
    {
      header: "语言学习",
      image: require("@/assets/images/ar-images/language-learning.png"),
      remarkList: [
        "通过扫描课本上的图片或文字，用户可以获得语音、翻译、语法等辅助学习信息。",
      ],
    },
    {
      header: "职业培训",
      image: require("@/assets/images/ar-images/vocational-training.png"),
      remarkList: [
        "通过扫描实际操作场景，用户可以获得相关的虚拟操作指南和安全提示。",
      ],
    },
    {
      header: "艺术课堂",
      image: require("@/assets/images/ar-images/art-classroom.png"),
      remarkList: [
        "学生可通过手势、语音等与虚拟艺术作品进行交互，如旋转、缩放、拆解艺术作品。",
      ],
    },
  ],
  "wechart-entertainment": [
    // AR小程序-娱乐
    {
      header: "旅游景点",
      image: require("@/assets/images/ar-images/tourist-attraction.png"),
      remarkList: [
        "用户可以通过微信小程序扫描景点标识牌或相关物品，获得景点介绍、历史背景、门店指引等信息。",
      ],
    },
    {
      header: "游戏",
      image: require("@/assets/images/ar-images/game.png"),
      remarkList: [
        "通过扫描现实场景中的道具或地点，用户可以获得游戏中的加分、解锁新场景等虚拟奖励。",
      ],
    },
    {
      header: "艺术展览",
      image: require("@/assets/images/ar-images/yszl.png"),
      remarkList: [
        "将艺术家的创作过程以动画、视频等形式叠加在作品上，让观众直观看到作品从无到有的演变。",
      ],
    },
  ],
  "wechart-navigation": [
    // AR小程序-导航
    {
      header: "展览/大型活动AR导航",
      image: require("@/assets/images/ar-images/large-exhibition.png"),
      remarkList: [
        "利用AR技术指引现场参观线路，为活动配套服务的入口提供必要指引，适用于大型展览、活动、演唱会等。",
      ],
    },
    {
      header: "商业空间AR导航",
      image: require("@/assets/images/ar-images/business-navigation.png"),
      remarkList: [
        "在商场、机场、高铁站等商业空间中提供AR导航服务，帮助消费者快速找到目标店铺或设施，提升购物体验。",
      ],
    },
    {
      header: "停车场AR导航",
      image: require("@/assets/images/ar-images/parking-navigation.png"),
      remarkList: [
        "通过给停车场部署AR导航导览，帮助车主在室内快速找车，适用于商超、医院、景区、高铁站、机场等大型停车场。",
      ],
    },
  ],
  "virtual-vr": [
    // VR虚拟仿真
    {
      header: "虚拟课堂",
      image: require("@/assets/images/ar-images/xnkt.png"),
      remarkList: [
        "K12学科教育课程开发",
        "STEM课堂开发",
        "高等教育/职业教育课件开发",
        "创新课堂建设",
      ],
    },
    {
      header: "虚拟实验室",
      image: require("@/assets/images/ar-images/xnsys.png"),
      remarkList: ["虚拟实训基地", "虚拟科学实验室", "物理/化学等学科实验室"],
    },
    {
      header: "虚拟展厅",
      image: require("@/assets/images/ar-images/xnzt.png"),
      remarkList: ["企业展厅", "学校校史陈列馆", "历史博物馆/艺术展厅"],
    },
    {
      header: "工业培训",
      image: require("@/assets/images/ar-images/gypx.png"),
      remarkList: ["虚拟装配", "维修维护", "技能培训"],
    },
    {
      header: "设计评审",
      image: require("@/assets/images/ar-images/sjps.png"),
      remarkList: ["工业设计评审", "课件设计评审", "房地产项目设计评审"],
    },
    {
      header: "虚拟数字人",
      image: require("@/assets/images/ar-images/xnszr.png"),
      remarkList: ["虚拟主播", "虚拟客服", "虚拟导游", "虚拟偶像"],
    },
    {
      header: "VR教育科普",
      image: require("@/assets/images/ar-images/jykp.png"),
      remarkList: ["红色文化基地", "党政建设", "历史知识/自然知识科普"],
    },
    {
      header: "虚拟艺术展演",
      image: require("@/assets/images/ar-images/xnyszy.png"),
      remarkList: ["沉浸式剧场", "虚拟艺术演出", "虚拟IP展览"],
    },
  ],
  "virtual-musenum": [
    // 虚拟博物馆
    {
      header: "博物馆",
      remarkList: [],
    },
    {
      header: "艺术馆",
      remarkList: [],
    },
    {
      header: "科技馆",
      remarkList: [],
    },
    {
      header: "历史馆",
      remarkList: [],
    },
    {
      header: "校史馆",
      remarkList: [],
    },
    {
      header: "成果馆",
      remarkList: [],
    },
  ],
  "nav-ar": [
    // AR导航导览
    {
      header: "旅游景区AR导览",
      remarkList: [
        "在景区内部署AR导航导览，为游客提供便捷的导航服务和丰富的景点介绍，提升游客的游览体验和满意度。",
      ],
    },
    {
      header: "博物馆和展厅AR导航",
      remarkList: [
        "利用AR技术展示逛展线路，并在参观动线中植入展品背后的故事和历史背景讲解等丰富信息。",
      ],
    },
    {
      header: "商业空间AR导航",
      remarkList: [
        "在商场、机场、高铁站等商业空间中提供AR导航服务，可以帮助消费者快速找到目标店铺或设施，提升购物体验。",
      ],
    },
    {
      header: "智慧园区AR导航",
      remarkList: [
        "在景区内部署AR导航导览系统，可以为游客提供便捷的导航服务和丰富的景点介绍，提升游客的游览体验和满意度。",
      ],
    },
    {
      header: "停车场AR导航",
      remarkList: [
        "通过给停车场部署AR导航导览，帮助车主在室内快速找车，适用于商超、医院、景区、高铁站、机场等大型停车场。",
      ],
    },
    {
      header: "大型活动AR导航",
      remarkList: [
        "利用AR技术指引现场参观线路，为活动配套服务的入口提供必要指引。",
      ],
    },
  ],
  "metaverse-hall": [
    // 元宇宙展厅
    {
      header: "文化艺术展览",
      remarkList: [
        "元宇宙展厅可以为艺术家和策展人提供一个全新的展示平台，让观众在虚拟空间中欣赏艺术作品，实现更加深入的艺术交流。",
      ],
    },
    {
      header: "商业展览",
      remarkList: [
        "商业展览中利用元宇宙展厅展示产品、服务和品牌形象，吸引更多潜在客户的关注，提升品牌影响力。",
      ],
    },
    {
      header: "教育培训",
      remarkList: [
        "元宇宙展厅可以模拟各种教育场景，为学生提供更加生动、直观的学习体验，提高学习效果。",
      ],
    },
    {
      header: "旅游观光",
      remarkList: [
        "通过元宇宙展厅，观众可以足不出户地游览世界各地的名胜古迹，感受不同文化的魅力。",
      ],
    },
    {
      header: "企业展厅",
      remarkList: [
        "企业部署元宇宙展厅，可以在物理展览的基础上叠加动态展示，更好地展示企业的产品、发展脉络、品牌文化和业务场景等。",
      ],
    },
    {
      header: "城市展厅",
      remarkList: [
        "城市元宇宙展厅可以以城市的发展脉络作为轴线，展示城市发展的历史沿革和精彩故事、文化沉淀、里程碑人物和事件等。",
      ],
    },
  ],
};

const technical_service = require("@/assets/images/ar-images/technical-service.png");
const customized_service = require("@/assets/images/ar-images/customized-service.png");
const saas_development = require("@/assets/images/ar-images/saas-development.png");

export const exampleDatas = {
  kjsj: {
    keyName: 'kjsj',
    example: [
      {
        title: "企业展厅空间采集演示",
        address: "上海光启园",
        url: require("@/assets/images/ar-images/qyztys.png"),
      },
      {
        title: "商场大空间采集演示",
        address: "上海徐汇漕河泾印象城",
        url: require("@/assets/images/ar-images/scdkj.png"),
      },
    ],
    itemList: [
      {
        title: "技术服务",
        remark: "直接采购混空空间视觉技术服务，用以配合后续项目开发使用。",
        className: "one",
        url: technical_service,
      },
      {
        title: "项目定制化开发",
        remark: "一站式委托项目开发，混空为客户定制开发交付元宇宙交钥匙工程。",
        className: "two",
        url: customized_service,
      },
    ],
  },
  arWechat: {
    keyName: 'arWechat',
    example: [
      {
        title: "商场AR智能导航",
        address: "上海美罗城",
        url: require("@/assets/images/ar-images/mlc.png"),
      },
      {
        title: "青田西餐厅AR小程序",
        address: "浙江青田",
        url: require("@/assets/images/ar-images/qtxct.png"),
      },
      {
        title: "百事可乐片段",
        address: "上海",
        url: require("@/assets/images/ar-images/bskl.png"),
      },
      {
        title: "AR节日营销",
        address: "上海",
        url: require("@/assets/images/ar-images/jryx.png"),
      },
    ],
    itemList: [
      {
        title: "SaaS化部署",
        remark:
          "通过混空自研的元宇宙虚实融合应用平台-混空Studio进行创作编辑，低成本、高效率，通过SaaS化快速创作AR导航导览内容。",
        className: "one",
        url: saas_development,
      },
      {
        title: "定制服务",
        remark:
          "根据项目实际需求，委托混空定制化开发AR导览，打造项目差异化竞争力，实现更好的沉浸式交互体验。",
        className: "two",
        url: customized_service,
      },
    ],
  },
  xnfz: {
    keyName: 'xnfz',
    example: [
      {
        title: "VR虚拟展厅",
        address: "山西太原",
        url: require("@/assets/images/ar-images/vr-xnzt.png"),
      },
      {
        title: "汽车虚拟装配",
        address: "江苏苏州",
        url: require("@/assets/images/ar-images/qcxnzp.png"),
      },
      {
        title: "沉浸式剧场",
        address: "陕西西安",
        url: require("@/assets/images/ar-images/cjsjc.png"),
      },
      {
        title: "化学实验VR课件",
        address: "北京",
        url: require("@/assets/images/ar-images/hxsykj.png"),
      },
    ],
  },
  xnbwg: {
    keyName: 'xnbwg',
    example: [
      {
        title: "国内博物馆",
        address: "江苏",
        url: require("@/assets/images/ar-images/gnbwg1.png"),
      },
      {
        title: "国内博物馆",
        address: "山东",
        url: require("@/assets/images/ar-images/gnbwg2.png"),
      },
      {
        title: "国内博物馆",
        address: "北京",
        url: require("@/assets/images/ar-images/gnbwg3.png"),
      },
      {
        title: "国内博物馆",
        address: "浙江",
        url: require("@/assets/images/ar-images/gnbwg4.png"),
      },
    ],
  },
  ardh: {
    keyName: 'ardh',
    example: [
      {
        title: "商场AR智能导航",
        address: "上海美罗城",
        url: require("@/assets/images/ar-images/mlc.png"),
      },
      {
        title: "车库找车AR导航",
        address: "北京",
        url: require("@/assets/images/ar-images/zcdh.png"),
      },
      {
        title: "旅游景区导航导览",
        address: "江西",
        url: require("@/assets/images/ar-images/lyjqdh.png"),
      },
      {
        title: "智慧园区导航",
        address: "上海",
        url: require("@/assets/images/ar-images/zhyqdh.png"),
      },
    ],
    itemList: [
      {
        title: "SaaS化部署",
        remark:
          "通过混空自研的元宇宙虚实融合应用平台-混空Studio进行创作编辑，低成本、高效率，通过SaaS化快速创作AR导航导览内容。",
        className: "one",
        url: saas_development,
      },
      {
        title: "定制服务",
        remark:
          "根据项目实际需求，委托混空定制化开发AR导览，打造项目差异化竞争力，实现更好的沉浸式交互体验。",
        className: "two",
        url: customized_service,
      },
    ],
  },
  yyzzt: {
    keyName: 'yyzzt',
    example: [
      {
        title: "钉钉展厅",
        address: "杭州",
        url: require("@/assets/images/ar-images/ddzt.png"),
      },
      {
        title: "企业元宇宙展厅",
        address: "上海光启园",
        url: require("@/assets/images/ar-images/qyyyz.png"),
      },
      {
        title: "《池中渔CHILL WANDER》",
        address: "苏州·湾美术馆",
        url: require("@/assets/images/ar-images/art-exhibition.png"),
      },
      {
        title: "高校成果展厅",
        address: "山西太原",
        url: require("@/assets/images/ar-images/gxcg.png"),
      },
    ],
    itemList: [
      {
        title: "SaaS化部署",
        remark:
          "通过混空自研的元宇宙虚实融合应用平台-混空Studio进行创作编辑，低成本、高效率，通过SaaS化快速创作AR导航导览内容。",
        className: "one",
        url: saas_development,
      },
      {
        title: "定制服务",
        remark:
          "根据项目实际需求，委托混空定制化开发AR导览，打造项目差异化竞争力，实现更好的沉浸式交互体验。",
        className: "two",
        url: customized_service,
      },
    ],
  },
};
