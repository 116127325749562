<template>
  <div>
    <div class="header-video">
       <img :src="loadUrl.home_banner_mp4" />
      <div class="video-text">
        <div class="link-room">打开「眼界」链接全新数字空间</div>
      </div>
      <div class="video-mask"></div>
    </div>
    <div class="core-advantages">
      <div>
        <div class="core-title">混空studio核心优势</div>
        <div>满足复杂场景设计需求</div>
        <div class="core-scene">
          <div class="studio-video">
            <img v-if="showStudioVideoPlay" src="@/assets/images/home/play-icon.png" @click="playStudioVideoPlay"/>
            <video :src="loadUrl.studio_mp4" loop muted autoplay @playing="playEvent"
              poster="@/assets/images/home/studio-mobile.jpg" playsinline webkit-playsinline></video>
          </div>
          <div style="background-color: #fff;" class="cost-base">
            <div class="title">成本低</div>
            <div class="cost-wrap">
              <div v-for="(item, index) in costList" :key="index">
                <div>
                  <div v-for="(e, i) in item" :key="i">{{ e }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="high-precision">
            <div class="title">高精度</div>
            <div class="precision-text">
              <div>自研高精度空间定位算法</div>
              <div>厘米级视觉定位</div>
              <div>空间导航精准</div>
              <div>支持大空间定位</div>
            </div>
            <div class="high-precision-image"></div>
          </div>
          <div class="easy-operate">
            <div class="title">易操作</div>
            <div class="easy-operate-image">
              <img :src="loadUrl.ycaozuo_gif" />
            </div>
            <div>
              <div>自研混空studio编辑工具</div>
              <div>3D场景地图，可视化交互“拖拉拽”操作</div>
              <div>所“建”即所得</div>
            </div>
          </div>
          <div class="arrange">
            <div class="title">部署快</div>
            <div class="arrange-icon">
              <div v-for="item in arrangeList" :key="item">
                <img :src="item" />
              </div>
            </div>
            <div>
              <div>仅需四步，快速部署</div>
              <div>支持快速导入模型、音效和互动元素</div>
              <div>无需安装，在网页端即可完成空间创作</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fusion">
      <div class="fusion-content">
        <div class="fusion-title">数实融合 虚实互生</div>
        <div class="fusion-tips">共同推动产业创新融合发展</div>
        <div class="fusion-window">
          <video v-for="(item, index) in metaversePlatformList" :style="{ opacity: activeFusionIndex == index ? 1 : 0 }"
            :src="item.url" :key="index" autoplay loop muted playsinline webkit-playsinline></video>
        </div>
        <div class="fusion-list-box">
          <div class="fusion-list-info">
            <div v-for="(item, index) in metaversePlatformList" :key="item.type"
              :class="activeFusionIndex == index ? 'active' : ''" @click="activeFusionIndex = index; viewTime = 1">
              <div class="metaverse-title">{{ item.title }}</div>
              <div class="metaverse-line">
                <div></div>
              </div>
              <div class="metaverse-content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="show-project">
      <div class="show-project-title">跨设备部署全场景展示每个项目</div>
      <div class="show-project-tips">文娱景区、剧场演绎、博物馆/文化馆、IP展览、商场、</div>
      <div class="show-project-tips">企业展厅、大型活动、品牌营销互动、广告策划、</div>
      <div class="show-project-tips">空间设计、教育培训、工业培训等</div>
      <banner-view :banner-data="projectTypeList"></banner-view>
    </div>
    <div class="partner-wrap">
      <div @click="router.push('/counselling')"></div>
    </div>
    <div class="bg-video">
      <img src="@/assets/images/home/particles-moment.png" />
      <div class="contact-us">
        <div class="title">成功提交信息，可享专业客服一对一支持</div>
        <div class="form-wrap-m">
          <el-input class="form-input" :class="checkEmpty.companyName ? 'error-cn' : ''"
            v-model="contactInfo.companyName" placeholder="公司名称" />
          <el-input class="form-input" :class="checkEmpty.name ? 'error-n' : ''" v-model="contactInfo.name"
            placeholder="姓名" />
          <el-input class="form-input" :class="showError || checkEmpty.phone ? 'error-text' : ''"
            v-model="contactInfo.phone" placeholder="电话" />
          <el-select placeholder="所属行业" :class="checkEmpty.industry ? 'error-i' : ''" v-model="contactInfo.industry"
            class="select-default" popper-class="select-option">
            <el-option v-for="(item, index) in industryList" :key="index" :label="item.name" :value="item.value" />
          </el-select>
        </div>
        <div class="submit-btn" @click="submitEvent">提交信息</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, watch, onUnmounted } from 'vue'
import { asynchronousRetrieval } from '@/utils'
import BannerView from '@/components/mobile/BannerView.vue';
import { addConsult } from '@/api'
import router from '@/router';

const loadUrl: any = ref({})
const costList = [['降低', '50%', '技术开发成本'], ['增加', '200%', '投资回报率'], ['节省', '80%', '部署时间'], ['综合提效', '300%', '创作落地']]
const arrangeList = [require('@/assets/images/home/list-icon.png'), require('@/assets/images/home/audio-bg-icon.png'), require('@/assets/images/home/service-bg-icon.png'), require('@/assets/images/home/blank-bg-icon.png'), require('@/assets/images/home/data-bg-icon.png'), require('@/assets/images/home/wifi-bg-icon.png'), require('@/assets/images/home/phone-bg-icon.png')]
const metaversePlatformList: any = ref(
  [
    {
      title: '文娱元宇宙',
      type: 1,
      content: '景区导览、遗迹重现、虚拟博物馆/文化馆/艺术馆、商业空间、IP展览、儿童游乐项目体验、企业展厅、营销互动、大型活动、虚拟数字人',
    },
    {
      title: '工业元宇宙',
      type: 2,
      content: '远程协助、数字孪生、点巡检、虚拟装配、维修维护、工厂漫游、技能培训、设计评审',
    },
    {
      title: '教育元宇宙',
      type: 3,
      content: '基础教育/高等教育/职业教育教学、播控、创新实验室建设、创新课堂建设、企业培训',
    }
  ]
)
const projectTypeList: any = ref(
  [
    {
      title: '微信小程序',
      type: 0,
      content: '高效创作XR/3D/数字人/虚拟展厅等元宇宙内容助力品牌/营销',
      frameNum: 198,
      key: 'wechat',
      diff: 7,
      imgs: [
        {
          url: loadUrl.value.wechat_gif,
          class: 'wechat-bg',
          size: [-276.5, -249]
        },
        {
          url: require('@/assets/images/home/wechat-icon.png'),
          class: 'wechat-icon',
          size: [-155, -157]
        },
        {
          url: require('@/assets/images/home/wechat-icon2.png'),
          class: 'wechat-icon2',
          size: [-46, 147]
        }
      ]
    },
    {
      title: 'VR端',
      type: 1,
      content: '高效创作XR/3D/数字人/虚拟展厅等元宇宙内容助力品牌/营销',
      frameNum: 108,
      key: 'VR',
      diff: 67,
      imgs: [
        {
          url: loadUrl.value.VR_gif,
          class: 'room-bg',
          size: [-276.5, -249]
        },
        {
          url: require('@/assets/images/home/rokid.png'),
          class: 'rokid',
          size: [-194, 39]
        }
      ]
    },
    {
      title: 'AR端',
      type: 2,
      content: '高效创作XR/3D/数字人/虚拟展厅等元宇宙内容助力品牌/营销',
      frameNum: 108,
      key: 'AR',
      diff: 0,
      imgs: [
        {
          url: loadUrl.value.AR_gif,
          class: 'ar-bg',
          size: [-276.5, -249]
        },
        {
          url: require('@/assets/images/home/ar-rokid.png'),
          class: 'ar-rokid',
          size: [-223, -6]
        }
      ]
    },
    {
      title: 'web端',
      type: 3,
      content: '高效创作XR/3D/数字人/虚拟展厅等元宇宙内容助力品牌/营销',
      frameNum: 108,
      key: 'web',
      diff: 5,
      imgs: [
        {
          url: loadUrl.value.web_gif,
          class: 'web-bg',
          size: [-276, -208]
        }
      ]
    },
  ]
)
const activeFusionIndex = ref(0)
const fusionVideo: any = ref([20.966667, 18.552993, 16.068005])
let timer: any = null;
const viewTime = ref(0)
const contactInfo: any = reactive({
  companyName: '',
  name: '',
  phone: '',
  industry: null
})
const industryList = [
  {
    name: '文娱',
    value: 1,
  },
  {
    name: '工业',
    value: 2,
  },
  {
    name: '教育',
    value: 3,
  },
  {
    name: '其他',
    value: 4,
  }
]
const showError = ref(false)
const checkEmpty: any = ref({})
const showStudioVideoPlay = ref(true) // 是否显示混空studio视频的播放按钮

const playStudioVideoPlay = () => {
  const studioVideo: any = document.querySelector('.studio-video video');
  studioVideo.play();
  showStudioVideoPlay.value = false;
}

const playEvent = () => {
  showStudioVideoPlay.value = false;
}

const submitEvent = () => {
  let reg = /^(130|131|132|133|134|135|136|137|138|139|145|146|147|148|149|150|151|152|153|155|156|157|158|159|166|170|171|173|174|175|176|177|178|180|181|182|183|184|185|186|187|188|189|191|198|199)\d{8}$/
  Object.keys(contactInfo).forEach((k) => {
    checkEmpty.value[k] = !contactInfo[k]
  })
  if (!reg.test(contactInfo.phone.replace(/\s+/g, "")) || !contactInfo.phone) {
    showError.value = true;
    return
  } else if (!contactInfo.companyName || !contactInfo.name || !contactInfo.industry) {
    return
  }
  addConsult({ ...contactInfo }).then((res: any) => {
    contactInfo.companyName = ''
    contactInfo.name = ''
    contactInfo.phone = ''
    contactInfo.industry = null
    showError.value = false;
    checkEmpty.value = {}
  })
}

const openTimer = () => {
  clearInterval(timer)
  timer = setInterval(() => {
    viewTime.value += 1;
    if (viewTime.value > fusionVideo.value[activeFusionIndex.value]) {
      activeFusionIndex.value += 1;
      if (activeFusionIndex.value > 2) {
        activeFusionIndex.value = 0
      }
      viewTime.value = 1;
    }
  }, 1000)
}

onMounted(async () => {
  openTimer()
  const home_banner_mp4: any = await asynchronousRetrieval('webimg/video/home-banner.gif');
  const studio_mp4: any = await asynchronousRetrieval('webimg/video/studio-mobile.mp4');
  const entertainment_mp4: any = await asynchronousRetrieval('webimg/video/entertainment.mp4');
  const industry_mp4: any = await asynchronousRetrieval('webimg/video/industry.mp4');
  const education_mp4: any = await asynchronousRetrieval('webimg/video/education.mp4');
  const wechat_gif: any = await asynchronousRetrieval('webimg/gif/wechat.gif');
  const VR_gif: any = await asynchronousRetrieval('webimg/gif/VR.gif');
  const AR_gif: any = await asynchronousRetrieval('webimg/gif/AR.gif');
  const web_gif: any = await asynchronousRetrieval('webimg/gif/web.gif');
  const ycaozuo_gif: any = await asynchronousRetrieval('webimg/ycaozuo.gif');
  const partner3: any = await asynchronousRetrieval('webimg/images/home/partner3.png');
  const bg_mask: any = await asynchronousRetrieval('webimg/images/home/bg-mask.png');
  loadUrl.value = { home_banner_mp4, studio_mp4, entertainment_mp4, industry_mp4, education_mp4, wechat_gif, VR_gif, AR_gif, web_gif, ycaozuo_gif, partner3, bg_mask }

  metaversePlatformList.value[0].url = entertainment_mp4;
  metaversePlatformList.value[1].url = industry_mp4;
  metaversePlatformList.value[2].url = education_mp4;
  projectTypeList.value[0].imgs[0].url = wechat_gif;
  projectTypeList.value[1].imgs[0].url = VR_gif;
  projectTypeList.value[2].imgs[0].url = AR_gif;
  projectTypeList.value[3].imgs[0].url = web_gif;
})

onUnmounted(() => {
  // window.removeEventListener('scroll', scrollEvent, false)
  clearInterval(timer)
  window.scrollTo(0, 0)
})

watch(viewTime, (newState) => {
  const metaverseLine: any = document.querySelectorAll('.metaverse-line>div');
  metaverseLine.forEach((ele: any) => {
    ele.style.width = 0
  })
  metaverseLine[activeFusionIndex.value].style.width = 300 / fusionVideo.value[activeFusionIndex.value] * newState + 'rem'
})

watch(activeFusionIndex, async (newState) => {
  const windowVideo: any = document.querySelectorAll('.fusion-window>video');
  windowVideo[newState].currentTime = 0;
})
</script>
<style scoped lang="less">
.header-video {
  width: 100%;
  height: 421rem;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &>img {
    width: 100%;
    height: 100%;
  }

  .video-text {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .link-room {
      width: 384rem;
      font-size: 48rem;
      color: #FFFFFF;
      text-align: center;
    }

    .experience-now {
      margin-top: 46rem;
      width: 160rem;
      height: 50rem;
      background: #0675FF;
      border-radius: 25rem;
      padding: 0 6rem 0 16rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      font-size: 24rem;

      .experience-btn {
        width: 38rem;
        height: 38rem;
        background: url(~@/assets/images/next-icon.png);
        background-size: 100% 100%;
      }
    }
  }
}

.core-advantages {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E1EFFF;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &>div {
    position: relative;
    width: 1220rem;
    padding: 62rem 0 50rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 24rem;
    color: #434343;
    z-index: 1;

    .core-title {
      font-size: 40rem;
      color: #0675FF;
      margin-bottom: 20rem;
      font-weight: 600;
    }

    .core-scene {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 46rem;
      width: 100%;

      .studio-video {
        position: relative;
        transition: all .5s;
        border: 1rem solid #999;
        height: 380rem;
        padding: 0;
        overflow: hidden;

        video {
          width: calc(100% + 4rem);
          height: calc(100% + 4rem);
          position: relative;
          left: -2rem;
          top: -2rem;
          object-fit: fill;
        }

        img {
          width: 68rem;
          height: 68rem;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -34rem;
          margin-top: -34rem;
          z-index: 1;
        }
      }

      &>div {
        position: relative;
        width: 680rem;
        background: #ddd;
        border-radius: 32rem;
        padding: 32rem 36rem;
        box-sizing: border-box;
        text-align: left;
        margin-bottom: 16rem;

        .title {
          font-weight: 600;
          font-size: 32rem;
          color: #000000;
          margin-bottom: 28rem;
        }

        &.cost-base {
          width: 680rem;
          height: 352rem;

          .cost-wrap {
            &>div {
              border-right: 1rem solid rgba(24, 24, 24, 0.2);
              box-sizing: border-box;
              text-align: left;
              overflow: hidden;
              display: inline-block;
              width: 25%;
              height: 200rem;
              float: left;
              box-sizing: border-box;
              padding-left: 24rem;

              &>div {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                height: 126rem;
                font-size: 32rem;
                color: #000000;
                transition: all ease-in-out .5s;
                font-weight: 600;

                &>div:first-child {
                  font-size: 28rem;
                  color: #000000;
                  font-weight: 600;
                }

                &>div:last-child {
                  font-size: 20rem;
                  color: #000000;
                }
              }
            }

            &>div:nth-child(2n)>div {
              margin-top: 52rem;
            }

            &>div:first-child {
              padding-left: 0;
            }
          }
        }
      }

      .high-precision {
        position: relative;
        background-color: #F5F9F3;
        background-image: url(~@/assets/images/home/high-precision-bg-m.png);
        background-size: 654rem 335rem;
        background-position: 12rem 8rem;
        background-repeat: no-repeat;
        overflow: hidden;
        transition: all .5s;
        height: 352rem;

        .high-precision-image {
          width: 362rem;
          height: 240rem;
          background: url(~@/assets/images/home/gjingdu.png);
          background-size: 100% 100%;
          position: absolute;
          left: 22rem;
          // left: -366rem;
          transition: all 1s;
          z-index: 2;
        }

        .precision-text {
          position: absolute;
          right: 26rem;
          bottom: 46rem;
          font-size: 24rem;
          color: #000000;
          line-height: 38rem;
          font-weight: 600;
        }
      }

      .easy-operate {
        position: relative;
        background-color: #E6F9FF;
        overflow: hidden;
        height: 400rem;
        transition: all .5s;

        .easy-operate-image {
          width: 528rem;
          height: 246rem;
          position: absolute;
          left: 76rem;
          // left: -626rem;
          bottom: 0;
          transition: all 1s;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &>div:last-child {
          font-size: 24rem;
          color: #000000;
          line-height: 36rem;
          text-align: left;
          position: absolute;
          right: 28rem;
          top: 32rem;
          font-weight: 600;
        }
      }

      .arrange {
        position: relative;
        background-color: #FFFEF9;
        overflow: hidden;
        height: 340rem;
        transition: all .5s;

        .arrange-icon {
          text-align: center;
          width: 376rem;
          font-size: 0;
          margin-top: -18rem;
          margin-left: -80rem;

          &>div {
            position: relative;
            width: 78rem;
            height: 78rem;
            display: inline-block;
            margin: 30rem 8rem 0;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &>div:first-child {
            margin-left: 20rem;
          }
        }

        &>div:last-child {
          width: 296rem;
          font-weight: 600;
          font-size: 24rem;
          color: #000000;
          line-height: 34rem;
          position: absolute;
          right: 22rem;
          top: 96rem;

          &>div {
            position: relative;
          }

          &>div::before {
            content: '';
            width: 3rem;
            height: 3rem;
            background-color: #000;
            position: absolute;
            left: -18rem;
            top: 14rem;
            border-radius: 100%;
          }
        }
      }
    }
  }
}

.fusion {
  position: relative;

  .fusion-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 1920rem;
    height: 1000rem;
    z-index: 12;

    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .fusion-content {
    position: relative;
    height: 854rem;
    background-color: #000000;
    padding-top: 64rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .fusion-title {
      font-size: 40rem;
      color: #FFFFFF;
      font-weight: 600;
    }

    .fusion-tips {
      font-weight: 500;
      font-size: 24rem;
      color: #FFFFFF;
      margin-top: 12rem;
      margin-bottom: 424rem;
    }

    .fusion-window {
      position: absolute;
      top: 180rem;
      left: 50%;
      margin-left: -340rem;
      width: 680rem;
      height: 364rem;
      background: url(~@/assets/images/home/window-bg.png);
      background-size: 100% 100%;
      box-sizing: border-box;
      transition: all 1s;

      video {
        width: 658rem;
        height: 344rem;
        border-radius: 20rem;
        position: absolute;
        left: 11rem;
        top: 10rem;
        opacity: 0;
        transition: all 0.5s;
        object-fit: fill;
      }
    }

    .fusion-list-box {
      width: 100%;
      height: 210rem;
      position: relative;
      overflow: hidden;
      overflow-x: auto;

      .fusion-list-info {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 939rem;
        text-align: left;
        padding: 0 96rem;

        &>div {
          width: 288rem;
          height: 209rem;
          position: relative;
          cursor: pointer;
        }

        .metaverse-title {
          font-weight: 600;
          font-size: 32rem;
          color: #FFFFFF;
        }

        .metaverse-line {
          position: relative;
          border-bottom: 1rem solid rgba(179, 179, 179, 0.24);
          width: 288rem;
          margin: 16rem 0;
        }

        .metaverse-content {
          font-weight: 500;
          font-size: 20rem;
          color: #B2B2B2;
          width: 288rem;
        }

        .view-more {
          width: 110rem;
          height: 36rem;
          line-height: 36rem;
          border-radius: 8rem;
          border: 1rem solid #FFFFFF;
          text-align: center;
          font-weight: 500;
          font-size: 14rem;
          color: #FFFFFF;
          position: absolute;
          left: 0;
          bottom: 0;
          cursor: pointer;
        }
      }

      .fusion-list-info>div.active {
        .metaverse-title {
          color: #91F7FF;
        }
      }
    }

    .metaverse-line>div {
      position: absolute;
      left: 0;
      top: 0;
      width: 0rem;
      height: 0;
      border-bottom: 1rem solid;
      border-image: linear-gradient(225deg, rgba(3, 255, 243, 1), rgba(0, 117, 255, 0.07)) 2 2;
    }
  }
}

.show-project {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  &::after {
    content: '';
    width: 100%;
    height: 152rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(6, 117, 255, 0.35) 60%, rgba(6, 117, 255, 0.8) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .show-project-title {
    font-weight: 600;
    font-size: 40rem;
    color: #0675FF;
    margin-top: 64rem;
    margin-bottom: 28rem;
  }

  .show-project-tips {
    font-weight: 500;
    font-size: 20rem;
    color: #0675FF;
    color: #797979;
    line-height: 28rem;
  }
}


.partner-wrap {
  position: relative;
  height: 610rem;
  background-color: #fff;
  // padding-left: 400rem;
  // padding-top: 196rem;
  box-sizing: border-box;
  text-align: left;
  overflow-x: hidden;
  background-image: url(~@/assets/images/home/bg-mask-m.png);
  background-size: 100% 100%;

  &>div {
    width: 103rem;
    height: 39rem;
    border-radius: 5rem;
    font-weight: 500;
    font-size: 14rem;
    position: absolute;
    left: 95rem;
    top: 256rem;
  }
}

.bg-video {
  position: relative;
  height: 536rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .contact-us {
    width: 680rem;
    height: 478rem;
    background: rgba(0, 0, 0, 0.08);
    box-shadow: inset 0rem 0rem 4rem 0rem rgba(255, 255, 255, 0.2);
    border-radius: 16rem;
    border: 0rem solid;
    border-image: linear-gradient(360deg, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.38)) 0 0;
    backdrop-filter: blur(1.17129774752586rem);
    position: relative;
    z-index: 1;
    padding: 24rem 32rem;
    box-sizing: border-box;

    .title {
      font-weight: 500;
      font-size: 28rem;
      color: #D3D3D3;
    }

    .form-wrap-m {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 26rem;

      &>div {
        position: relative;
        width: 326rem;
        // height: 36rem;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 5rem;
        margin-bottom: 16rem;
      }
    }

    .submit-btn {
      width: 158rem;
      height: 56rem;
      background: #0675FF;
      border-radius: 5rem;
      line-height: 56rem;
      text-align: center;
      font-weight: 500;
      font-size: 22rem;
      color: #FFFFFF;
      margin-top: 12rem;
      margin-left: 227rem;
      box-sizing: border-box;
    }
  }
}
</style>